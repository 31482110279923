<template>
  <div class="mt-8 text-brown-4 px-6">
    <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="privacy-policy">Privacy Policy</h2>
    <p class="text-base lg:text-2xl mb-8">
        Lay Bare Group of Companies (in reference to Lay Bare Waxing
        Philippines and its sister companies and affiliates shall be
        collectively referred as "Lay Bare", "we", "us" or "our")
        understands the importance of protecting your Personal Data. Our
        policy describes how we collect, protect and manage information
        systems, and processes, and other related systems.
    </p>

    <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="why-we-process-your-data">Why we process your data</h2>
    <p class="text-base lg:text-2xl mb-8">
        To keep you up to date with the latest content including promos,
        announcements, products, invitation and event launch. Manage any
        existing membership you have to us. Regulate your bookings,
        queries and feedback through our online surveys, etc. Use a
        variety of marketing channels to tap our existing contacts and
        ensure that they are well-informed.
    </p>
    
    <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="limitation">Limitation</h2>
    <p class="text-base lg:text-2xl mb-8">
        Personal data shall be collected for specified and legitimate
        purposes and not further that expected. Your personal data shall
        be processed lawfully, fairly and in a transparent manner in
        accordance with the data subject rights.
    </p>

    <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="integrity-and-confidentiality">Integrity and Confidentiality</h2>
    <p class="text-base lg:text-2xl mb-8">
        Your personal shall be processed with reasonable and appropriate
        organizational, physical and technical security measures to
        ensure protection of the data against unauthorized processing,
        accidental loss, alteration or damages.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>