<template>
    <div class="md:col-span-4 text-brown-4 px-6 w-full">
        <div class="flex flex-col lg:flex-row lg:justify-center lg:gap-24 lg:mt-12 mb-4">
            <img class="w-36 md:w-52 lg:w-36 2xl:w-auto mx-auto lg:m-0" src="../../assets/images/privacy-policy/NPC-seal.png" alt="NPC Seal" />
            <img class="w-auto lg:w-96 2xl:w-auto" src="../../assets/images/privacy-policy/RegistrationCert.png" alt="Registration Certificate" />
        </div>

         <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="privacy-notice">Privacy Notice</h2>
        <p class="text-base lg:text-2xl mb-8">At Lay Bare, we value your concerns about privacy and we want to make sure you feel comfortable using our websites and applications.</p>
        <h2 class="text-2xl md:text-3xl mb-4 font-semibold">Introduction</h2>
        <p class="text-base lg:text-2xl mb-8">Lay Bare Group of Companies (hereafter, “Lay Bare”) understands the importance of protecting your Personal Data.
            Our policy describes how we collect, protect, and manage information from you through our websites, information systems, and processes (forms and contracts),
            and other related systems. Lay Bare respects the confidentiality of Personal Data and is committed to comply with Republic Act No. 10173 or the Data Privacy Act of 2012.
            We value data privacy rights and herein ensure that all Personal Data collected from our customer, vendors, partners, employees, associates, and other stakeholders,
            are processed and stored in compliance to the general principles of transparency, for a legitimate purpose and proportionality. </p>
            
            <p class="lg:text-2xl font-semibold">This Privacy Policy explains the following:</p>
            <ul class="list-disc text-base lg:text-2xl list-outside mb-8">
                <li class="list-item">What information we collect and why we collect it</li>
                <li class="list-item">How we use the said information and to whom we may disclose it</li>
                <li class="list-item">The choices available for you to manage your personal information</li>
                <li class="list-item">How we safeguard your Personal Data</li>
            </ul>

            <p class="text-base lg:text-2xl mb-8">By providing Lay Bare with your Personal Data in the use of any of our products and
            services as described in this Privacy Policy, you have explicitly authorized and consented to our collection, use, access,
            transfer, storage, and processing of said Personal Data.</p>

            <h2 class="text-2xl md:text-3xl mb-4 font-semibold">Terms</h2>
            <p class="lg:text-2xl font-semibold mb-4">The following terms are defined under the Data Privacy Act of 2012 as follows:</p>
            <ol class="list-decimal text-base lg:text-2xl list-outside mb-8">
            <li class="list-item">“<strong>Personal Data</strong>” refers to all types of personal information</li>
            <li class="list-item">“<strong>Personal Information</strong>” refers to any information,
                whether recorded in material form or not, from which the identity of an individual
                is apparent or can be reasonably and directly ascertained by the entity holding the information,
                or when put together with other information would directly and certainly identify an individual</li>
            <li class="list-item">
                <span>“<strong>Sensitive personal information</strong>” refers to personal information:</span>
                <ul class="list-disc list-inside mb-8">
                    <li>About an individual’s race, ethnic origin, marital status, age, color, and religious, philosophical, or political affiliations;</li>
                    <li>About an individual’s health, education, genetic or sexual life of a person,
                    or to any proceeding for any offense committed or alleged to have been committed by such individual,
                    the disposal of such proceedings, or the sentence of any court in such proceedings;</li>
                    <li>Issued by government agencies peculiar to an individual which includes, but is not limited to,
                    social security numbers, previous or current health records, licenses or its denials,
                    suspension or revocation, and tax returns; and</li>
                    <li>Specifically established by an executive order or an act of Congress to be kept classified.</li>
                </ul>
            </li>
            </ol>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="personal-data-and-other-information-we-collect">Personal Data And Other Information We Collect</h2>
        <ol class="list-decimal mb-8">
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Consent</h4>
            <p class="text-base lg:text-2xl mb-8">By buying/availing of our products and services, you may have provided your Personal Data to us.
                Your personal data may also be obtained passively by buying and availing our services and products.
                As such, your rights under this Privacy Policy shall be respected pursuant to the Data Privacy Act of 2012.</p>
                <p class="text-base lg:text-2xl mb-8 underline">By providing your consent through our ‘consent document’ or a ‘Pre-Session Consent Form’,
                both from our branches and at our Lay Bare App, your data shall be processed and protected under the provisions of this policy.</p>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Personal Information</h4>
            <p class="text-base lg:text-2xl mb-8">As part of our dealings with you, we may collect the following Personal Data when necessary, including but not limited to:</p>
                <ul class="list-disc list-inside mb-8 text-base lg:text-2xl">
                <li>Your name, gender, civil status, date of birth, address, telephone or mobile numbers, email address, mailing address, proof of identification and any other
                    information relating to you which you have provided us in submission of any form as a result of our interaction with you.</li>
                    <li>Your credit history, bank account, and credit card information that you provide us in submitting your franchise application.</li>
                    <li>Your employment history, education background, resume, and income information when you apply for a job with us.</li>
                    <li>Information about your visit and use of our websites, digital platforms, and mobile apps, including but not limited to
                    social media profile information, IP Addresses, your browsing behavior within and throughout our digital assets, and session lengths,
                    are collected by our website analytics tools and cookies that we may place on your computer. You may disable the use of cookies on your browser at
                    any time.</li>
                    <li>Your social media behavior when you tag, mention, or post photographs of us on any social media account (e.g. Facebook, Twitter, Instagram, LinkedIn, etc.)</li>
                    <li>Your work-related information regarding your company, your ownership, and participation when you apply for vendor accreditation.</li>
                </ul>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Collecting And Storing Personal Data</h4>
            <p class="text-base lg:text-2xl mb-8">We may collect and store your Personal Information under the following circumstances:</p>
            <ul class="list-disc list-inside mb-8 text-base lg:text-2xl">
                <li>When you purchase or avail of any of our products, services, promos, activities, and events.</li>
                <li>When you interact with any of our employees, through email, phone, chat services, or face-to-face meetings.</li>
                <li>When you submit information through any or all forms of our digital platforms (i.e. websites, apps),
                    or connect with us through any of our social media accounts (e.g. Google, Facebook, Twitter, Instagram, LinkedIn, etc.)</li>
                <li>When you provide personal information in relation to inquiries, requests, and complaints.</li>
                <li>When you respond to surveys, promotions, and other marketing and sales initiatives.</li>
                <li>When you submit a job application.</li>
                <li>When we receive references from third parties or our business partners (i.e. when you have been referred by them).</li>
                <li>When you submit your personal data to us for any other reason.</li>
                </ul>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Personal Information Collection And Processed Through Third-Party Social Media Accounts.</h4>
            <p class="text-base lg:text-2xl mb-8">Lay Bare may also collect your personal information when you use social media to use Lay Bare’s Services,
                interact with Lay Bare, or log-in through Lay Bare through your social media account.</p>
                <p class="text-base lg:text-2xl mb-8">For purposes of the same, Lay Bare may collect and process Personal Information such as:</p>
                <ul class="list-disc list-inside mb-8 text-base lg:text-2xl">
                <li>Your Name</li>
                <li>Your Email Address</li>
                <li>Any other additional information that you consent to sharing with Lay Bare</li>
                </ul>
                <p class="text-base lg:text-2xl mb-8">Should you choose to share this information with Lay Bare you are giving the company the permission to process, disclose,
                and retain the same in a manner consistent with Lay Bare’s <i>Privacy Policy</i>.</p>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Personal Information Collected And Processed Through The Lay Bare Application.</h4>
            <p class="text-base lg:text-2xl mb-8">Lay Bare may also collect your personal information when you register, log-in, and use the Lay Bare Application.</p>
                <p class="text-base lg:text-2xl mb-8">For purposes of the same, Lay Bare may collection and process Personal Information such as:</p>
                <ul class="list-disc list-inside mb-8 text-base lg:text-2xl">
                <li>Your Name</li>
                <li>Your Email Address</li>
                <li>Your Mobile Number</li>
                <li>Your Log-In Credentials</li>
                <li>Information regarding your Location</li>
                <li>Information about your Device such as Brand, Type, MAC Address, etc.</li>
                <li>Your Activities within the Application</li>
                </ul>
                <p class="text-base lg:text-2xl mb-8">Lay Bare’s Access to Personal Information provided through the application may be enabled or disabled through your device settings.</p>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Personal Data Other Than Yours</h4>
            <p class="text-base lg:text-2xl mb-8">When you disclose to us another person’s personal information (i.e. your dependent, spouse, children and/or parents’ information) you attest
                and warrant that consent has been duly obtained from that person to disclose and process personal information in accordance with this policy.</p>
                <p class="text-base lg:text-2xl mb-8">For purposes of the same, Lay Bare may collection and process Personal Information such as:</p>
            </li>

        </ol>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="use-and-disclosure-of-personal-data">Use And Disclosure Of Personal Data</h2>
        <ol class="list-decimal mb-8">
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Uses Of Your Personal Data</h4>
            <p class="text-base lg:text-2xl mb-8">Lay Bare collects, uses, and discloses your Personal Information for the following purposes:</p>
                <ul class="list-disc list-inside mb-8 text-base lg:text-2xl">
                <li>To provide you with products and services that you have requested.</li>
                <li>To enhance your customer experience by delivering product services that match your preference and needs.</li>
                <li>To allow you to access the features of the services offered.</li>
                <li>To improve and customize your user experience.</li>
                <li>To communicate relevant information including responses to your queries, requests, and complaints.</li>
                <li>To store the same in Lay Bare’s services for its retention period.</li>
                <li>To provide you information about our products and services which may be of interest to you.</li>
                <li>To comply with the requirements of the law and legal proceedings such as court orders; comply with legal obligation,
                    or to prevent imminent harm to public security safety or order.</li>
                <li>To prevent, detect, and investigate possible violation of Lay Bare rules and regulations, which may include violations of
                    Philippine Law, and to manage and mitigate risks to prevent the same.</li>
                <li>To process information for statistical, analytical, and research purposes.</li>
                </ul>
            </li>

            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Other Uses By Nature Of Our Dealings</h4>
            <p class="text-base lg:text-2xl mb-8">Aside from the general uses mentioned previously, we may likewise use your personal data in any of the following instances depending on your dealings with us,
                in any of the following manner:</p>
                <ul class="list-circle list-outside mb-8 text-base lg:text-2xl">
                <li class="mb-4">
                    <span class="lg:text-2xl font-semibold">WHEN YOU APPLY TO BECOME A FRANCHISEE</span>
                    <ul class="list-square list-inside">
                    <li>To conduct appropriate due diligence checks.</li>
                    <li>Register and process your application.</li>
                    <li>To evaluate applications and to conduct corresponding background checks.</li>
                    <li>To perform financial transactions such as the processing payments and perform other actions necessary.</li>
                    <li>Any other purpose deemed necessary.</li>
                    </ul>
                </li>

                <li class="mb-4">
                    <span class="lg:text-2xl font-semibold">WHEN YOU ARE A VENDOR, A PROSPECTIVE VENDOR, OR A CONTRACTOR</span>
                    <ul class="list-square list-inside">
                    <li>To conduct appropriate due diligence checks.</li>
                    <li>To evaluate proposals and to conduct corresponding background checks, if necessary.</li>
                    <li>To assess your viability as a vendor and process your accreditation.</li>
                    <li>To communicate with you about matters relating to our required products and services.</li>
                    <li>Any other purpose deemed necessary.</li>
                    </ul>
                </li>
                
                <li class="mb-4">
                    <span class="lg:text-2xl font-semibold">WHEN YOU APPLY FOR EMPLOYMENT OR BECOME OUR EMPLOYEE</span>
                    <ul class="list-square list-inside">
                    <li>To process your application including any of the following:
                        <ul class="list-disc list-inside">
                            <li>Conducting a background check.</li>
                            <li>Conducting aptitude and personality tests and checks.</li>
                            <li>Conduct of interviews.</li>
                            <li>Facilitating medical check-ups and clearances.</li>
                            <li>Collecting information about your suitability for the position.</li>
                        </ul>
                    </li>
                    <li>To administer benefits and payroll processing.</li>
                    <li>To assess your performance.</li>
                    <li>To perform other actions necessary or desirable in the implementation of your employment contract.</li>
                    <li>Any other purpose deemed necessary.</li>
                    </ul>
                </li>
                </ul>
            </li>

            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Disclosure Of Your Personal Data</h4>
            <p class="text-base lg:text-2xl mb-8">Lay Bare will not intentionally disclose or transfer (and will take reasonable steps to prevent
                the unauthorized or accidental disclosure of) your personal data to third parties without your consent or as otherwise permitted by law,
                whether for such third parties’ own marketing purposes or otherwise, except as follows:</p>
                <ul class="list-circle list-outside mb-8 text-base lg:text-2xl">
                <li>We may disclose your personal data to the following as reasonably necessary, depending on the product or service concerned:
                    <ul class="list-square list-inside mb-8">
                    <li>Our Employees and Officers;</li>
                    <li>Professional advisers such as lawyers and auditors;</li>
                    <li>Insurers and credit providers;</li>
                    <li>Banks, credit card companies and their respective service provider</li>
                    <li>Suppliers or subcontractors, third party service providers, consultants to provide financial,
                        technical, architectural, administrative services such as information technology, payroll, accounting,
                        sales administration, procurement, training, and other services;</li>
                        <li>Any third-party businesses offering goods and services or sponsor contests or other marketing and promotional programs.</li>
                    </ul>
                </li>
                </ul>
                <ul class="list-circle list-outside mb-8 text-base lg:text-2xl">
                <li>We may likewise disclose your personal information as reasonably necessary and for the purpose set out in this policy to any of the following:
                    <ul class="list-square list-inside mb-8">
                    <li>Members of our group of companies or our subsidiaries and affiliates;
                        <ul class="list-disc list-inside">
                        <li>Customer Care Team</li>
                        <li>Franchise Operations and Relation Department</li>
                        <li>Human Capital Team</li>
                        <li>Enterprise Innovation department</li>
                        <li>Operation Team</li>
                        <li>Compliance team</li>
                        </ul>
                    </li>
                    <li class="list-square list-inside mb-8">
                        Our joint venture and alliance partners;
                        <ul class="list-disc list-inside">
                        <li>Consultants</li>
                        </ul>
                    </li>
                    </ul>
                    For more information on our partners and affiliates, kindly refer to the link provided:
                    <button @click="previewFile" class="underline">List of Individuals</button>
                </li>
                </ul>
                <ul class="list-circle list-outside mb-8 text-base lg:text-2xl">
                <li>We may disclose your personal data:
                    <ul class="list-square list-inside mb-8">
                    <li>To the extent that we are required to do so by applicable law and/or rules and regulations;</li>
                    <li>In connection with any ongoing or prospective legal proceedings;</li>
                    <li>To the clients and/or customers availing of our products and services;</li>
                    <li>To support auditing, compliance, and corporate governance functions;</li>
                    <li>In so far as it is necessary for the protection of lawful rights and interests of natural or legal persons in court proceedings,
                        or the establishment, exercise, or defense of legal claims, or when provided to government or public authority pursuant to a constitutional,
                        statutory, or any legal mandate;</li>
                    <li>Any company with which we share information about you for the above purposes is contractually required to comply with confidentiality standards,
                        undertake to respect any individual's right to privacy and comply with the Data Privacy Act. We also require that these organizations use this
                        information only for our purposes and follow our reasonable directions with respect to this information. This includes organizations, which handle or obtain Personal
                        Information as service providers.</li>
                    </ul>
                </li>
                
                </ul>
            </li>
        </ol>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="international-data-transfers">International Data Transfers</h2>
        <ol class="list-decimal mb-8">
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Cloud Services</h4>
            <p class="text-base lg:text-2xl mb-8">Information that we collect may be stored and processed in and transferred between any of
                the countries in which we make use of cloud services in order to enable us to use the information in accordance with this policy.</p>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">Internet</h4>
            <p class="text-base lg:text-2xl mb-8">Personal data that you publish on our website or submit for publication on our website may be available
                via the Internet, around the world. We cannot prevent the use or misuse of such information by others.</p>
            </li>
        </ol>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="retention-and-disposal-of-your-personal-information">Retention And Disposal Of Your Personal Information</h2>
        <p class="text-base lg:text-2xl mb-8 underline">Lay Bare will retain personal data, as necessary, for the duration of <strong>Five (5) years</strong> from the expiry of our business relationship with you or
            any matter relating thereto which shall include, but is not limited to, legal proceedings, requirements of government agencies, or any other requirements
            set forth by law.</p>
        <p class="text-base lg:text-2xl mb-8">Thereafter your personal information shall be disposed of or discarded in a secure manner that would prevent further processing,
            unauthorized access, or disclosure to any other party or the public. To know more, please refer to Lay Bare’s Data Retention and Disposal Policy. </p>
        
        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="your-rights">Your Rights</h2>
        <p class="text-base lg:text-2xl mb-8">Where granted by applicable law, you have the right to the following:</p>
        <ol class="list-decimal mb-8 text-base lg:text-2xl">
            <li>Request reasonable access to the personal data that we have collected from you for the purposes of reviewing, modifying, or requesting deletion of the data.</li>
            <li>To request a copy of the personal data that we have collected from you and to have any inaccuracies in that data corrected. </li>
            <li>In certain circumstances, you may also request that we cease processing your personal data. If you wish to withdraw your consent given for any or all purposes set out in this policy,
            you may send your detailed request to <a class="underline" href="mailto: dpo.lbwpi@lay-bare.com">dpo.lbwpi@lay-bare.com</a>. Depending on the nature of your consent withdrawal, Lay Bare may not be in a position to pursue any future
            transactions with you.</li>
        </ol>
        <p class="text-base lg:text-2xl mb-8">To help protect your privacy and security, we will take reasonable steps to verify your identity, by, but not limited to requiring your Client ID before granting you access to your personal data.
            We will make reasonable attempts to promptly investigate, comply with, or otherwise respond to your requests as may be required by applicable law.
            Different laws may prevent us from providing you access to your personal data or otherwise fully
            accommodating your request depending upon the circumstances and the request, such as for example, where producing your information may reveal the identity of someone else.</p>
        <p class="text-base lg:text-2xl mb-8">We reserve the right to charge the appropriate fee for accommodating your request where allowed by applicable law.
            We likewise reserve the right to deny your requests where they may be manifestly unfounded, and/or excessive, impractical,
            or otherwise objectionable or unwarranted under applicable law.</p>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="protecting-your-information">Protecting Your Information</h2>
        <ol class="list-decimal mb-8 text-base lg:text-2xl">
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">PROTECTING INFORMATION YOU SUBMIT ONLINE</h4>
            <p class="text-base lg:text-2xl mb-8">We take precautions to protect your information. When you submit sensitive information via any of our official website(s), information is protected both online and offline.
                Wherever we collect sensitive information (such as credit card data), this is passed on through our payment gateway, which encrypts and transmits to us in a secure way.
                All our critical websites undergo security-scanning penetration testing to reduce our websites’ vulnerability against cyber security attacks. While we employ all necessary precautions to safeguard your information,
                the Internet is not 100% secure so we cannot guarantee that information we collect or store will be protected from all unauthorized access and thereby used in a manner that is not inconsistent with this Privacy Policy.</p>
            </li>
            <li>
            <h4 class="lg:text-2xl font-semibold mb-4">HOW WE SAFEGUARD YOUR PERSONAL INFORMATION</h4>
            <p class="text-base lg:text-2xl mb-8">We maintain physical, technical, and organizational safeguards to protect your personal data against loss, theft, unauthorized access, disclosure,
                copying, use or modification. We also put in effect safeguards such as:</p>
                <ul class="list-circle mb-8 list-outside">
                <li>Using secured servers behind advanced threat protection appliances, firewalls, encryptions and other security tools.</li>
                <li>We limit access to your personal information to those who are qualified and authorized to process them. All such persons, wherever they are located, are required by us to protect
                    the confidentiality and privacy of your personal data in a manner consistent with our privacy policies and practices.</li>
                    <li>Our information system/infrastructure undergo regular security vulnerability and penetration testing to ensure your personal information is properly protected.</li>
                </ul>
            </li>
        </ol>
        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="third-party-websites">Third Party Websites</h2>
        <p class="text-base lg:text-2xl mb-8">Our websites may contain hyperlinks and details of third-party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.
            We therefore advise you to review their own privacy policy. Once you leave our website, you should check the applicable privacy policy of the third-party website to determine how they will handle any information they collect from you.</p>
        
        <h2 class="text-3xlmb-4 font-semibold" id="cookies-and-similar-technologies">Cookies And Similar Technologies</h2>
        <p class="text-base lg:text-2xl mb-8">A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web
            traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and
            dislikes by gathering and remembering information about your preferences.</p>
        <p class="text-base lg:text-2xl mb-8">Lay Bare uses traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve your experience and
            tailor fit our website/Application to ourcustomer’s needs. We only use this information for statistical analysis purposes, after which the data is removed from the system.</p>
        <p class="text-base lg:text-2xl mb-8">Overall, cookies help us provide you with a better website/application, by enabling us to monitor which pages you find useful and which you do not.
            A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. We may use your browsing behavior to develop promotional offers,
            products, and services that we feel are beneficial to you.</p>

        <h4 class="lg:text-2xl font-semibold mb-4">Cookies we use.</h4>
        <p class="text-base lg:text-2xl mb-8">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies
            are deleted as soon as you close your web browser.</p>
        <p class="text-base lg:text-2xl mb-8">Lay Bare uses both Session and Persistent Cookies for the purposes set out below:</p>
        <ul class="list-outside">
            <li>
                <h4 class="lg:text-2xl font-semibold">Necessary / Essential Cookies</h4>
                <p class="text-base lg:text-2xl">Type: Session Cookies</p>
                <p class="text-base lg:text-2xl">Administered by: Us</p>
                <p class="text-base lg:text-2xl">Purpose: These Cookies are essential to provide you with services available through the Website and to enable you to use some of its features. They help to authenticate users and prevent
                    fraudulent use of user accounts. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.</p>
            </li>
            <li>
                <h4 class="lg:text-2xl font-semibold">Functionality Cookies</h4>
                <p class="text-base lg:text-2xl">Type: Persistent Cookies</p>
                <p class="text-base lg:text-2xl">Administered by: Us</p>
                <p class="text-base lg:text-2xl">Purpose: These Cookies allow us to remember choices you make when you use the Website and Application, such as remembering your login details or language preference.
                    The purpose of these Cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website/Application.</p>
            </li>
        </ul>

        <h4 class="lg:text-2xl font-semibold mb-4">Your Choices regarding Cookies.</h4>
        <p class="text-base lg:text-2xl mb-8">You can choose to accept or decline cookies. Most web browsers/applications automatically accept cookies, but you can usually modify your browser setting to decline cookies
            if you prefer. This may prevent you from taking full advantage of the website/application.</p>
        <p class="text-base lg:text-2xl mb-8">Should you prefer to avoid the use of Cookies on Lay Bare’s Website/Application, you must first disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>
        <p class="text-base lg:text-2xl mb-8">If you do not accept our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>
        <p class="text-base lg:text-2xl mb-8">If you'd like to delete Cookies or instruct your web browser/device to delete or refuse Cookies, please visit the help pages of your web browser.</p>
        <ul class="mb-8 text-base lg:text-2xl w-full">
            <li>For the Chrome web browser, please visit this page from Google: 
                <a class="underline break-all" href="https://support.google.com/accounts/answer/32050" target="_blank">https://support.google.com/accounts/answer/32050</a>
            </li>
            <li>For the Internet Explorer web browser, please visit this page from Microsoft: 
                <a class="underline" href="https://support.microsoft.com/kb/278835" target="_blank">https://support.microsoft.com/kb/278835</a>
            </li>
            <li>For the Firefox web browser, please visit this page from Mozilla: 
                <a class="underline" href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank">
            https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
            </li>
            <li>For the Safari web browser, please visit this page from Apple: 
                <a class="underline" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">
            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
            </li>
        </ul>
        <p class="text-base lg:text-2xl mb-8">For any other web browser, please visit your web browser's official web pages.</p>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="management-and-security">Management And Securiry</h2>
        <p class="text-base lg:text-2xl mb-8">A Data Privacy Officer for Lay Bare and corresponding Compliance Officers of Privacy for our subsidiaries have been appointed per affected subsidiaries
            to manage and safeguard the handling of your Personal Data compliant with the Data Privacy Act of 2012 and this Policy. We are committed to ensure that Lay Bare’s Privacy Management Program is
            constantly reviewed, monitored, and enhanced.</p>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="our-data-protection-office">Our Data Protection Office</h2>
        <p class="text-base lg:text-2xl mb-8">Our Data Protection Officer is responsible for overseeing what we do with your information and monitoring our compliance with the Data Privacy Act of 2012.</p>
        <p class="text-base lg:text-2xl mb-8">If you have any concerns or questions about our use of your personal data, you can contact our Data
            Privacy Team by writing to <a class="underline" href="mailto: dpo.lbwpi@lay-bare.com">dpo.lbwpi@lay-bare.com</a>.</p>

        <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="updates-to-the-policy">Updates To The Policy</h2>
        <p class="text-base lg:text-2xl mb-8">Lay Bare will amend this Privacy from time to time. We may also update this policy to apply changes due to new laws and regulation affecting the data privacy act
            changes in our business operations and environment. Any updates of changes will be posted on our website with a date stamped so that you are informed of the latest Policy update.</p>
    </div>
</template>

<script>
export default {
    methods: {
        previewFile() {
            window.open('/files/Privacy-Notice-List-Of-Individuals.pdf', '_blank');
        }
    }
}
</script>

<style>

</style>